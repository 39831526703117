import React, { useState, useEffect } from 'react'
import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Button,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader
} from 'reactstrap'
import { Link, useHistory } from 'react-router-dom'

import { useHaus } from '~/haus'

export function UpdateHausModal (props) {
  const { updateHaus } = useHaus()
  const [name, setName] = useState(props.name)
  const [about, setAbout] = useState(props.about)
  const [errors, setErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [hasChanged, setHasChanged] = useState(false)

  useEffect(() => {
    setHasChanged(props.name != name || props.about != about)
  })

  useEffect(() => {
    errors.name && setErrors({ ...errors, name: undefined })
  }, [name])

  useEffect(() => {
    errors.about && setErrors({ ...errors, about: undefined })
  }, [about])

  function update () {
    event.preventDefault()

    setIsLoading(true)

    updateHaus({ about, name })
      .then(props.toggle)
      .catch(({ response }) => {
        setErrors(response.data.errors || {})
        setIsLoading(false)
      })
  }

  function cancel () {
    setName(props.name)
    setAbout(props.about)
    props.toggle()
  }

  return (
    <Modal isOpen={props.isOpen} toggle={cancel} fade={false}>
      <ModalHeader toggle={cancel}>Update Haus</ModalHeader>
      <ModalBody>
        <Form onSubmit={update} noValidate>
          <FormGroup>
            <Label for='name'>Name</Label>
            <Input
              type='name'
              value={name}
              onChange={({ target }) => setName(target.value)}
              invalid={!!errors.name}
              name='name'
              id='name'
              placeholder='Name'
            />
            {errors.name &&
              errors.name.map((msg, index) => (
                <FormFeedback key={index}>Name {msg}.</FormFeedback>
              ))}
          </FormGroup>
          <FormGroup>
            <Label for='about'>About</Label>
            <Input
              type='textarea'
              value={about}
              onChange={({ target }) => setAbout(target.value)}
              invalid={!!errors.about}
              name='about'
              id='about'
              placeholder='About'
              rows={5}
            />
            {errors.about &&
              errors.about.map((msg, index) => (
                <FormFeedback key={index}>About {msg}.</FormFeedback>
              ))}
            <FormText>
              This field supports{' '}
              <a href='https://www.markdownguide.org/basic-syntax'>Markdown</a>.
            </FormText>
          </FormGroup>
          <hr />
          <Button
            type='submit'
            disabled={isLoading || !hasChanged}
            color='primary'
          >
            {isLoading ? 'Updating' : 'Update'}
          </Button>
          <Button color='link' onClick={cancel}>
            Cancel
          </Button>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export function NewHaus () {
  const { createHaus } = useHaus()
  const [name, setName] = useState('')
  const [about, setAbout] = useState('')
  const [errors, setErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()

  useEffect(() => {
    errors.name && setErrors({ ...errors, name: undefined })
  }, [name])

  useEffect(() => {
    errors.about && setErrors({ ...errors, about: undefined })
  }, [about])

  function submit () {
    event.preventDefault()

    setIsLoading(true)

    createHaus({ about, name })
      .then(() => history.push('/'))
      .catch(({ response }) => {
        setErrors(response.data.errors || {})
        setIsLoading(false)
      })
  }

  function cancel () {
    history.push('/')
  }

  return (
    <Container className='new-haus'>
      <Card>
        <CardHeader>New Haus</CardHeader>
        <CardBody>
          <Form onSubmit={submit} noValidate>
            <FormGroup>
              <Label for='name'>Name</Label>
              <Input
                type='name'
                value={name}
                onChange={({ target }) => setName(target.value)}
                invalid={!!errors.name}
                name='name'
                id='name'
                placeholder='Name'
              />
              {errors.name &&
                errors.name.map((msg, index) => (
                  <FormFeedback key={index}>Name {msg}.</FormFeedback>
                ))}
            </FormGroup>
            <FormGroup>
              <Label for='about'>About</Label>
              <Input
                type='textarea'
                value={about}
                onChange={({ target }) => setAbout(target.value)}
                invalid={!!errors.about}
                name='about'
                id='about'
                placeholder='About'
                rows={5}
              />
              {errors.about &&
                errors.about.map((msg, index) => (
                  <FormFeedback key={index}>About {msg}.</FormFeedback>
                ))}
              <FormText>
                This field supports{' '}
                <a href='https://www.markdownguide.org/basic-syntax'>
                  Markdown
                </a>
                .
              </FormText>
            </FormGroup>
            <hr />
            <Button type='submit' disabled={isLoading} color='primary'>
              {isLoading ? 'Submitting' : 'Submit'}
            </Button>
            <Link to='/' className='btn btn-link'>
              Cancel
            </Link>
          </Form>
        </CardBody>
      </Card>
    </Container>
  )
}
