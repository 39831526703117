import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { ProvideHaus } from '~/haus'
import Icon from '~/icons'

import Account from './accounts'
import Overview from './overview'
import Navigation from './navigation'
import { MembersView } from './members'
import { PaymentsView } from './payments'
import { NewHaus } from './hauses'

function Authenticated () {
  return (
    <ProvideHaus>
      <Navigation />
      <Switch>
        <Route path='/members' component={MembersView} />
        <Route path='/payments' component={PaymentsView} />
        <Route path='/account' component={Account} />
        <Route path='/new-haus' component={NewHaus} />
        <Route path='/' component={Overview} />
      </Switch>
    </ProvideHaus>
  )
}

export default Authenticated
