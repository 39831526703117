import { useState, useEffect } from 'react'

function now () {
  return Math.floor(Date.now() / 1000)
}

function isStale (createdAt, maxAge) {
  return createdAt == null || createdAt < now() - maxAge
}

function decode (value) {
  return JSON.parse(value)
}

function encode (value, createdAt) {
  return JSON.stringify({ value, createdAt })
}

function getStorage (key, maxAge) {
  const existing = localStorage.getItem(key)

  if (existing == null) {
    return { value: undefined, createdAt: undefined }
  }

  const { value, createdAt } = decode(existing)

  if (isStale(createdAt, maxAge)) {
    return { value: undefined, createdAt: undefined }
  }

  return { value, createdAt }
}

export default function useLocalStorage (key, maxAge) {
  let createdAt = undefined

  const [value, setValue] = useState(() => {
    const storage = getStorage(key, maxAge)
    createdAt = storage.createdAt
    return storage.value
  })

  useEffect(() => {
    if (value == null) {
      localStorage.removeItem(key)
      createdAt = undefined
    } else {
      localStorage.setItem(key, encode(value, createdAt || now()))
    }
  }, [key, value])

  return [value, setValue]
}
