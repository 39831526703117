import React, { useCallback, useState, useEffect } from 'react'
import {
  Container,
  Card,
  CardBody,
  CardHeader,
  Container,
  Button,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Table
} from 'reactstrap'
import { Link, useHistory } from 'react-router-dom'
import debounce from 'lodash/debounce'

import api from '~/api'
import { useHaus } from '~/haus'
import { useAuth } from '~/auth'
import { AddPaymentModal } from './payments'

export function MembersView () {
  const [users, setUsers] = useState([])
  const { haus } = useHaus()
  const [addPaymentModals, setAddPaymentModals] = useState({})

  useEffect(refreshMembers, [haus])

  function refreshMembers () {
    if (!haus) return

    return api
      .get(`/hauses/${haus.id}/users`)
      .then(response => response.data.records)
      .then(setUsers)
  }

  function toggleAddPaymentModal (user) {
    const tmp = { ...addPaymentModals }

    if (tmp[user.id]) {
      delete tmp[user.id]
    } else {
      tmp[user.id] = true
    }

    setAddPaymentModals(tmp)
  }

  return (
    <Container>
      <Table responsive borderless striped hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user.id}>
              <td>
                {user.first_name} {user.last_name}
              </td>
              <td>{user.email}</td>
              <td className='float-right'>
                {haus.is_admin && (
                  <Button
                    outline
                    size='sm'
                    color='primary'
                    onClick={() => toggleAddPaymentModal(user)}
                  >
                    Add Payment
                    <AddPaymentModal
                      isOpen={addPaymentModals[user.id]}
                      user={user}
                      toggle={() => toggleAddPaymentModal(user)}
                      onSuccess={refreshMembers}
                    />
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  )
}

export function AddMemberModal (props) {
  const { haus, createMember } = useHaus()
  const [user, setUser] = useState()
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [errors, setErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  function getUserByEmail (email) {
    return api
      .get(`/users?email=${email}`)
      .then(response => response.data.records)
      .then(records => (records.length ? records[0] : undefined))
  }

  const search = useCallback(
    debounce(email => {
      getUserByEmail(email).then(setUser)
    }, 250),
    []
  )

  useEffect(() => {
    email && search(email)
  }, [email])

  useEffect(() => {
    if (user) {
      setFirstName(user.first_name)
      setLastName(user.last_name)
    } else {
      setFirstName('')
      setLastName('')
    }
  }, [user])

  useEffect(() => {
    if (errors.email || errors.user_id) {
      setErrors({ ...errors, email: undefined, user_id: undefined })
    }
  }, [email])

  useEffect(() => {
    errors.first_name && setErrors({ ...errors, first_name: undefined })
  }, [firstName])

  useEffect(() => {
    errors.last_name && setErrors({ ...errors, last_name: undefined })
  }, [lastName])

  function provisionMember () {
    return api
      .post(`/users`, {
        email,
        first_name: firstName,
        last_name: lastName
      })
      .then(response => response.data)
      .then(user => {
        setUser(user)
        return createMember(user)
      })
      .then(() => {
        props.onSuccess()
        close()
      })
      .catch(({ response }) => {
        setErrors(response.data.errors || {})
        setIsLoading(false)
      })
  }

  function addMember () {
    return createMember(user)
      .then(() => {
        props.onSuccess()
        close()
      })
      .catch(({ response }) => {
        setErrors(response.data.errors || {})
        setIsLoading(false)
      })
  }

  function submit () {
    event.preventDefault()

    setIsLoading(true)

    if (user) {
      addMember()
    } else {
      provisionMember()
    }
  }

  function close () {
    setUser()
    setEmail('')
    setFirstName('')
    setLastName('')
    setIsLoading(false)
    props.toggle()
  }

  return (
    <Modal isOpen={props.isOpen} toggle={close} fade={false}>
      <ModalHeader toggle={close}>Add Member</ModalHeader>
      <ModalBody>
        <Form onSubmit={submit} noValidate>
          <FormGroup>
            <Label for='email'>Email</Label>
            <Input
              type='email'
              value={email}
              onChange={({ target }) => setEmail(target.value)}
              invalid={!!errors.email || !!errors.user_id}
              name='email'
              id='email'
              placeholder='Email'
            />
            {errors.email &&
              errors.email.map((msg, index) => (
                <FormFeedback key={`email:${index}`}>Email {msg}.</FormFeedback>
              ))}
            {errors.user_id &&
              errors.user_id.map((msg, index) => (
                <FormFeedback key={`user_id:${index}`}>
                  User {msg}.
                </FormFeedback>
              ))}
          </FormGroup>
          <FormGroup>
            <Label for='firstName'>First Name</Label>
            <Input
              value={firstName}
              onChange={({ target }) => setFirstName(target.value)}
              invalid={!!errors.first_name}
              name='firstName'
              id='firstName'
              placeholder='First Name'
              disabled={!!user}
            />
            {errors.first_name &&
              errors.first_name.map((msg, index) => (
                <FormFeedback key={index}>First name {msg}.</FormFeedback>
              ))}
          </FormGroup>
          <FormGroup>
            <Label for='lastName'>Last Name</Label>
            <Input
              value={lastName}
              onChange={({ target }) => setLastName(target.value)}
              invalid={!!errors.last_name}
              name='lastName'
              id='lastName'
              placeholder='Last Name'
              disabled={!!user}
            />
            {errors.last_name &&
              errors.last_name.map((msg, index) => (
                <FormFeedback key={index}>Last Name {msg}.</FormFeedback>
              ))}
          </FormGroup>
          <hr />
          <Button type='submit' disabled={isLoading} color='primary'>
            {isLoading ? 'Adding' : 'Add'}
          </Button>
          <Button color='link' onClick={close}>
            Cancel
          </Button>
        </Form>
      </ModalBody>
    </Modal>
  )
}
