import React, { useState, useEffect } from 'react'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
  Form,
  FormGroup,
  FormFeedback,
  Input
} from 'reactstrap'
import { Link } from 'react-router-dom'

import { useAuth } from './auth'

function Login () {
  const { login } = useAuth()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    errors.email && setErrors({ ...errors, email: undefined })
  }, [email])

  useEffect(() => {
    errors.password && setErrors({ ...errors, password: undefined })
  }, [password])

  function submit (event) {
    event.preventDefault()

    setIsLoading(true)

    login(email, password).catch(({ response }) => {
      setErrors(response.data.errors || {})
      setIsLoading(false)
    })
  }

  return (
    <Container className='login'>
      <Card>
        <CardHeader className='text-center lead'>
          <strong>Ski Haus</strong>
        </CardHeader>
        <CardBody>
          <Form onSubmit={submit} noValidate>
            <FormGroup>
              <Input
                type='email'
                value={email}
                onChange={({ target }) => setEmail(target.value)}
                invalid={!!errors.email}
                name='email'
                placeholder='Email'
              />
              {errors.email &&
                errors.email.map((msg, index) => (
                  <FormFeedback key={index}>Email {msg}.</FormFeedback>
                ))}
            </FormGroup>
            <FormGroup>
              <Input
                type='password'
                value={password}
                onChange={({ target }) => setPassword(target.value)}
                invalid={!!errors.password}
                name='password'
                placeholder='Password'
              />
              {errors.password &&
                errors.password.map((msg, index) => (
                  <FormFeedback key={index}>Password {msg}.</FormFeedback>
                ))}
            </FormGroup>
            <Button block disabled={isLoading} color='primary'>
              {isLoading ? 'Logging In' : 'Log In'}
            </Button>
          </Form>
        </CardBody>
        <CardFooter>
          <Link to='/forgot-password'>Forgot Password?</Link>
        </CardFooter>
      </Card>
    </Container>
  )
}

export default Login
