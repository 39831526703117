import React from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom'

import { ProvideAuth, AuthRoute } from './auth'

import Authenticated from './authenticated'
import Login from './login'
import ForgotPassword from './forgot-password'
import ResetPassword from './reset-password'

function App () {
  return (
    <ProvideAuth>
      <Router>
        <Switch>
          <AuthRoute required={false} path='/login' component={Login} />
          <AuthRoute
            required={false}
            path='/forgot-password'
            component={ForgotPassword}
          />
          <AuthRoute
            required={false}
            path='/reset-password'
            component={ResetPassword}
          />
          <AuthRoute required={true} path='/' component={Authenticated} />
        </Switch>
      </Router>
    </ProvideAuth>
  )
}

export default App
