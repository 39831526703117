import React, { useState, useEffect } from 'react'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Form,
  FormGroup,
  FormFeedback,
  Input
} from 'reactstrap'
import { useLocation } from 'react-router-dom'

import { useAuth } from './auth'

function ResetPassword () {
  const { resetPassword } = useAuth()

  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const search = useLocation().search
  const token = new URLSearchParams(search).get('token')

  useEffect(() => {
    errors.password && setErrors({ ...errors, password: undefined })
  }, [password])

  function submit (event) {
    event.preventDefault()

    setIsLoading(true)

    resetPassword(token, password).catch(({ response }) => {
      setErrors(response.data.errors || {})
      setIsLoading(false)
    })
  }

  function getButtonText () {
    if (isLoading) {
      return 'Submitting'
    } else if (errors.token) {
      return 'Invalid Token'
    } else {
      return 'Submit'
    }
  }

  return (
    <Container className='reset-password'>
      <Card>
        <CardHeader className='text-center lead'>
          <strong>Reset Password</strong>
        </CardHeader>
        <CardBody>
          <Form onSubmit={submit} noValidate>
            <FormGroup>
              <Input
                type='password'
                value={password}
                onChange={({ target }) => setPassword(target.value)}
                invalid={!!errors.password}
                name='password'
                placeholder='Password'
              />
              {errors.password &&
                errors.password.map((msg, index) => (
                  <FormFeedback key={index}>Password {msg}.</FormFeedback>
                ))}
            </FormGroup>
            <Button block disabled={isLoading || errors.token} color='primary'>
              {getButtonText()}
            </Button>
          </Form>
        </CardBody>
      </Card>
    </Container>
  )
}

export default ResetPassword
