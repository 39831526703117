import React, { useState, useEffect, useContext, createContext } from 'react'

import api from './api'
import { useAuth } from './auth'

const hausContext = createContext()

export function useHaus () {
  return useContext(hausContext)
}

export function ProvideHaus ({ children }) {
  const haus = useProvideHaus()
  return <hausContext.Provider value={haus}>{children}</hausContext.Provider>
}

function getHaus (hauses) {
  return hauses.length ? hauses[0] : undefined
}

function getHauses (user) {
  return api
    .get(`/users/${user.id}/hauses`)
    .then(response => response.data.records)
}

function useProvideHaus () {
  const [hauses, setHauses] = useState([])
  const [haus, setHaus] = useState()

  const { auth } = useAuth()

  function set () {
    if (auth == null) {
      setHauses()
      setHaus()
      return
    }

    return getHauses(auth.user).then(hauses => {
      if (!hauses.length) {
        return
      }

      setHauses(hauses)
      setHaus(hauses[0])
    })
  }

  useEffect(set, [auth])

  function updateHaus (data) {
    return api.put(`/hauses/${haus.id}`, data).then(({ data }) => {
      setHaus({ ...haus, ...data })
      setHauses(
        hauses.map(record =>
          record.id === data.id ? { ...record, ...data } : record
        )
      )
    })
  }

  function createHaus (data) {
    return api.post(`/hauses`, data).then(({ data }) => {
      setHaus({ ...data, is_admin: true })
      setHauses([{ ...data, is_admin: true }, ...hauses])
    })
  }

  function createMember (user) {
    return api.post(`/hauses/${haus.id}/members`, { user_id: user.id })
  }

  return { hauses, haus, setHaus, updateHaus, createHaus, createMember }
}
