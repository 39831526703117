import React, { useEffect, useContext, createContext } from 'react'
import { Route, Redirect } from 'react-router-dom'

import api from './api'
import useLocalStorage from './use-local-storage'

const SESSION_MAX_AGE = 86400

const authContext = createContext()

export function useAuth () {
  return useContext(authContext)
}

export function AuthRoute ({ component: Component, required, ...rest }) {
  const { auth } = useAuth()

  function render () {
    if (required) {
      return auth ? <Component /> : <Redirect to='/login' />
    } else {
      return auth ? <Redirect to='/' /> : <Component />
    }
  }

  return <Route {...rest} render={render} />
}

export function ProvideAuth ({ children }) {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

function setAuthHeader (auth) {
  api.defaults.headers = auth ? { Authorization: `Bearer ${auth.token}` } : {}
}

function useProvideAuth () {
  const [auth, setAuth] = useLocalStorage('auth', SESSION_MAX_AGE)

  setAuthHeader(auth)
  useEffect(() => setAuthHeader(auth), [auth])

  function login (email, password) {
    return api
      .post('/auth/login', { email, password })
      .then(({ data }) => setAuth(data))
  }

  function resetPassword (token, password) {
    return api
      .post('/auth/reset-password', { token, password })
      .then(({ data }) => setAuth(data))
  }

  function logout () {
    setAuth()
  }

  function updateAccount (data) {
    return api.put(`/users/${auth.user.id}`, data).then(({ data: user }) => {
      setAuth({ ...auth, user })
    })
  }

  function changePassword (password) {
    return api.post(`/auth/change-password`, { password })
  }

  return { auth, login, logout, updateAccount, changePassword, resetPassword }
}
