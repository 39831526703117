import React, { useState, useEffect } from 'react'
import {
  Container,
  Table,
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader
} from 'reactstrap'
import NumberFormat from 'react-number-format'
import { DateTime } from 'luxon'

import api from '~/api'
import { useHaus } from '~/haus'
import { dollarsToCents, centsToDollars } from './utils'

export function PaymentsView () {
  const [users, setUsers] = useState({})
  const [payments, setPayments] = useState([])
  const { haus } = useHaus()

  useEffect(refreshPayments, [haus])
  useEffect(refreshUsers, [haus])

  function refreshPayments () {
    if (!haus) return

    return api
      .get(`/hauses/${haus.id}/payments`)
      .then(response => response.data.records)
      .then(setPayments)
  }

  function refreshUsers () {
    if (!haus) return

    return api
      .get(`/hauses/${haus.id}/users`)
      .then(response => response.data.records)
      .then(users => {
        setUsers(
          users.reduce((acc, user) => {
            acc[user.id] = user
            return acc
          }, {})
        )
      })
  }

  return (
    <Container>
      <Table responsive borderless striped hover>
        <thead>
          <tr>
            <th>Member</th>
            <th>Amount</th>
            <th>Note</th>
            <th>Made On</th>
          </tr>
        </thead>
        <tbody>
          {payments.map(payment => {
            const user = users[payment.user_id]
            return (
              <tr key={payment.id}>
                <td>{user && `${user.first_name} ${user.last_name}`}</td>
                <td>
                  <NumberFormat
                    displayType='text'
                    thousandSeparator={true}
                    prefix={'$'}
                    value={centsToDollars(payment.amount)}
                  />
                </td>
                <td>{payment.note}</td>
                <td>
                  {DateTime.fromISO(payment.inserted_at).toLocaleString(
                    DateTime.DATE_MED
                  )}
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </Container>
  )
}

export function AddPaymentModal (props) {
  const [amount, setAmount] = useState()
  const [note, setNote] = useState('')
  const [errors, setErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const { haus } = useHaus()

  useEffect(() => {
    if (errors.amount) {
      setErrors({ ...errors, amount: undefined })
    }
  }, [amount])

  useEffect(() => {
    errors.note && setErrors({ ...errors, note: undefined })
  }, [note])

  function submit () {
    event.preventDefault()

    setIsLoading(true)

    return api
      .post(`/hauses/${haus.id}/users/${props.user.id}/payments`, {
        amount: dollarsToCents(amount),
        note
      })
      .then(() => {
        props.onSuccess()
        close()
      })
      .catch(({ response }) => {
        setErrors(response.data.errors || {})
        setIsLoading(false)
      })
  }

  function close () {
    setAmount(0)
    setNote('')
    setIsLoading(false)
    props.toggle()
  }

  return (
    <Modal isOpen={props.isOpen} toggle={close} fade={false}>
      <ModalHeader toggle={close}>Add Payment</ModalHeader>
      <ModalBody>
        <Form onSubmit={submit} noValidate>
          <FormGroup>
            <Label for='email'>Amount</Label>
            <NumberFormat
              name='amount'
              id='amount'
              placeholder='Amount'
              type='tel'
              value={amount}
              onValueChange={({ floatValue }) => {
                setAmount(floatValue)
              }}
              decimalScale={2}
              thousandSeparator={true}
              prefix={'$'}
              allowNegative={false}
              className={`form-control ${!!errors.amount && 'is-invalid'}`}
            />
            {errors.amount &&
              errors.amount.map((msg, index) => (
                <FormFeedback key={index}>Amount {msg}.</FormFeedback>
              ))}
          </FormGroup>
          <FormGroup>
            <Label for='note'>Note</Label>
            <Input
              value={note}
              type='text'
              onChange={({ target }) => setNote(target.value)}
              invalid={!!errors.note}
              name='note'
              id='note'
              placeholder='Note'
            />
            {errors.note &&
              errors.note.map((msg, index) => (
                <FormFeedback key={index}>Note {msg}.</FormFeedback>
              ))}
          </FormGroup>
          <hr />
          <Button type='submit' disabled={isLoading} color='primary'>
            {isLoading ? 'Adding' : 'Add'}
          </Button>
          <Button color='link' onClick={close}>
            Cancel
          </Button>
        </Form>
      </ModalBody>
    </Modal>
  )
}
