import React, { useState, useEffect } from 'react'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
  Form,
  FormGroup,
  FormFeedback,
  Input
} from 'reactstrap'
import { useHistory } from 'react-router-dom'

import { useAuth } from './auth'
import api from '~/api'

function ForgotPassword () {
  const [email, setEmail] = useState('')
  const [errors, setErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [isComplete, setIsComplete] = useState(false)

  useEffect(() => setIsComplete(false), [email])

  useEffect(() => {
    errors.email && setErrors({ ...errors, email: undefined })
  }, [email])

  function submit (event) {
    event.preventDefault()

    setIsLoading(true)

    api
      .post(`/auth/forgot-password`, { email })
      .then(() => {
        setIsLoading(false)
        setIsComplete(true)
      })
      .catch(({ response }) => {
        setErrors(response.data.errors || {})
        setIsLoading(false)
      })
  }

  function getButtonText () {
    if (isLoading) {
      return 'Submitting'
    } else if (isComplete) {
      return 'Email Sent'
    } else {
      return 'Submit'
    }
  }

  return (
    <Container className='forgot-password'>
      <Card>
        <CardHeader className='text-center lead'>
          <strong>Forgot Password</strong>
        </CardHeader>
        <CardBody>
          <Form onSubmit={submit} noValidate>
            <FormGroup>
              <Input
                type='email'
                value={email}
                onChange={({ target }) => setEmail(target.value)}
                invalid={!!errors.email}
                name='email'
                placeholder='Email'
              />
              {errors.email &&
                errors.email.map((msg, index) => (
                  <FormFeedback key={index}>Email {msg}.</FormFeedback>
                ))}
            </FormGroup>
            <Button block disabled={isLoading || isComplete} color='primary'>
              {getButtonText()}
            </Button>
          </Form>
        </CardBody>
        <CardFooter>
          You will receive a link at the provided email with instructions to
          reset your password.
        </CardFooter>
      </Card>
    </Container>
  )
}

export default ForgotPassword
