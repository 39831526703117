import React, { useState, useEffect } from 'react'
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  ListGroupItemText,
  Row
} from 'reactstrap'
import NumberFormat from 'react-number-format'
import { Link } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import { DateTime } from 'luxon'

import api from '~/api'
import { centsToDollars } from './utils'
import { useAuth } from '~/auth'
import { useHaus } from '~/haus'
import { UpdateHausModal } from './hauses'
import { AddMemberModal } from './members'

function HausOverview () {
  const [users, setUsers] = useState([])
  const [payments, setPayments] = useState([])
  const { haus } = useHaus()
  const { auth } = useAuth()

  const [updateModal, setUpdateModal] = useState(false)
  const [addMemberModal, setAddMemberModal] = useState(false)

  useEffect(refreshMembers, [haus])
  useEffect(refreshPayments, [haus])

  function refreshMembers () {
    return api
      .get(`/hauses/${haus.id}/users`)
      .then(response => response.data.records)
      .then(setUsers)
  }

  function refreshPayments () {
    return api
      .get(`/hauses/${haus.id}/users/${auth.user.id}/payments`)
      .then(response => response.data.records)
      .then(setPayments)
  }

  function toggleUpdateModal () {
    setUpdateModal(!updateModal)
  }

  function toggleAddMemberModal () {
    setAddMemberModal(!addMemberModal)
  }

  return (
    <Row>
      <Col md='6'>
        <Card>
          <CardHeader>
            {haus.name}
            {haus.is_admin && (
              <>
                <Button
                  onClick={toggleUpdateModal}
                  color='primary'
                  size='sm'
                  className='float-right'
                >
                  Update
                </Button>
                <UpdateHausModal
                  isOpen={updateModal}
                  toggle={toggleUpdateModal}
                  key={JSON.stringify(haus)}
                  {...haus}
                />
              </>
            )}
          </CardHeader>
          <CardBody>
            <ReactMarkdown>{haus.about}</ReactMarkdown>
          </CardBody>
        </Card>
        <Card>
          <CardHeader>Payments</CardHeader>
          {payments.length ? (
            <ListGroup flush>
              {payments.map(payment => (
                <ListGroupItem key={payment.id}>
                  <ListGroupItemText>
                    <NumberFormat
                      displayType='text'
                      thousandSeparator={true}
                      prefix={'$'}
                      value={centsToDollars(payment.amount)}
                      className='font-weight-bold'
                    />
                    <span className='float-right'>
                      {DateTime.fromISO(payment.inserted_at).toLocaleString(
                        DateTime.DATE_MED
                      )}
                    </span>
                  </ListGroupItemText>
                  {payment.note && (
                    <ListGroupItemText>
                      <small>{payment.note}</small>
                    </ListGroupItemText>
                  )}
                </ListGroupItem>
              ))}
            </ListGroup>
          ) : (
            <CardBody>
              <Alert color='info'>You have not made any payments yet.</Alert>
            </CardBody>
          )}
        </Card>
      </Col>
      <Col md='6'>
        <Card>
          <CardHeader>
            Members
            <>
              <Button
                onClick={toggleAddMemberModal}
                color='primary'
                size='sm'
                className='float-right'
              >
                Add
              </Button>
              <AddMemberModal
                isOpen={addMemberModal}
                toggle={toggleAddMemberModal}
                onSuccess={refreshMembers}
              />
            </>
          </CardHeader>
          <ListGroup flush>
            {users.map(user => (
              <ListGroupItem key={user.id}>
                {user.first_name} {user.last_name}{' '}
                {user.is_admin && <Badge className='float-right'>Admin</Badge>}
              </ListGroupItem>
            ))}
          </ListGroup>
        </Card>
      </Col>
    </Row>
  )
}

function Overview () {
  const { haus } = useHaus()

  return (
    <Container>
      {haus ? (
        <HausOverview />
      ) : (
        <Alert color='info'>
          You are not associated to a haus yet. You can wait until you are added
          to a haus or click <Link to='/new-haus'>here</Link> to create a new
          one.
        </Alert>
      )}
    </Container>
  )
}

export default Overview
