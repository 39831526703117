import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faUserCircle,
  faSignOutAlt,
  faHome
} from '@fortawesome/free-solid-svg-icons'

library.add(faUserCircle, faSignOutAlt, faHome)

export default FontAwesomeIcon
