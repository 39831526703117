import React, { useState } from 'react'
import {
  Container,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Button,
  Popover,
  PopoverBody,
  ListGroup,
  ListGroupItem,
  Container
} from 'reactstrap'
import { NavLink as RouterNavLink, Link } from 'react-router-dom'

import { useAuth } from '~/auth'
import { useHaus } from '~/haus'
import Icon from '~/icons'

function Navigation () {
  const { auth, logout } = useAuth()
  const { haus, hauses, setHaus } = useHaus()
  const [popover, setPopover] = useState(false)

  function togglePopover () {
    setPopover(!popover)
  }

  function selectHaus (haus) {
    setHaus(haus)
    setPopover(false)
  }

  return (
    <>
      <Nav className='bg-light root'>
        <Navbar color='light' className='mr-auto'>
          <Link to='/' className='navbar-brand'>
            Ski Haus
          </Link>
        </Navbar>
        <Navbar color='light'>
          <NavItem>
            <a
              href='#'
              className='nav-link'
              onClick={togglePopover}
              id='haus-selecter-toggler'
            >
              <Icon icon='home' size='lg' />
              {haus && (
                <span className='d-none d-md-inline-block'>{haus.name}</span>
              )}
              <Popover
                isOpen={popover}
                placement='bottom'
                innerClassName='haus-picker'
                target='haus-selecter-toggler'
              >
                <PopoverBody>
                  {!!hauses.length && (
                    <ListGroup flush>
                      {hauses.map(record => (
                        <ListGroupItem
                          disabled={haus && haus.id == record.id}
                          key={record.id}
                          onClick={() => selectHaus(record)}
                        >
                          {record.name}
                        </ListGroupItem>
                      ))}
                    </ListGroup>
                  )}
                  <Container>
                    <Link
                      to='/new-haus'
                      className='btn btn-primary btn-block'
                      onClick={togglePopover}
                    >
                      New Haus
                    </Link>
                  </Container>
                </PopoverBody>
              </Popover>
            </a>
          </NavItem>
          <NavItem>
            <Link to={`/account`} className='nav-link'>
              <Icon icon='user-circle' size='lg' />
              <span className='d-none d-md-inline-block'>
                {auth.user.email}
              </span>
            </Link>
          </NavItem>
          <NavItem>
            <NavLink onClick={logout} href='#'>
              <Icon icon='sign-out-alt' size='lg' />
              <span className='d-none d-md-inline-block'>Logout</span>
            </NavLink>
          </NavItem>
        </Navbar>
      </Nav>
      <Container>
        <Nav>
          <NavItem>
            <RouterNavLink exact to='/' className='nav-link'>
              Overview
            </RouterNavLink>
          </NavItem>
          <NavItem>
            <RouterNavLink to='/members' className='nav-link'>
              Members
            </RouterNavLink>
          </NavItem>
          {haus && haus.is_admin && (
            <NavItem>
              <RouterNavLink to='/payments' className='nav-link'>
                Payments
              </RouterNavLink>
            </NavItem>
          )}
          <NavItem>
            <RouterNavLink to='/account' className='nav-link'>
              Account
            </RouterNavLink>
          </NavItem>
        </Nav>
      </Container>
    </>
  )
}

export default Navigation
